<template>
  <div class="page">
    <img src="../../assets/images/productBG.png" class="backgroundPic"/>
    <div class="flexBoxCol">
      <div class="p48-400 mtp50 mbm50">产品系统构成</div>
      <div class="span24">智能硬件+智慧校园云平台+微信小程序</div>
      <div class="homeIconLine mbm50">
        <div class="homeIconBox">
          <svg-icon icon-class="hardware"></svg-icon>
          <span class="span24 mlt15">智能硬件</span>
        </div>
        <div class="homeIconBox">
          <svg-icon icon-class="cloud"></svg-icon>
          <span class="span24 mlt15">智慧校园云平台</span>
        </div>
        <div class="homeIconBox">
          <svg-icon icon-class="applet"></svg-icon>
          <span class="span24 mlt15">微信小程序</span>
        </div>
      </div>
      <div class="homeIconLine mbm50">
        <div class="imgBox mrt50">
          <img src="../../assets/images/homePic1.png" class="homePic bg-color-95CDF7"/>
          <p class="p36">闸机</p>
        </div>
        <div class="imgBox mrt50">
          <img src="../../assets/images/homePic4.png" class="homePic"/>
          <p class="p36">电子学生证</p>
        </div>
        <div class="imgBox mrt50">
          <img src="../../assets/images/homePic8.png" class="homePic bg-color-95CDF7"/>
          <p class="p36">人脸识别面板机</p>
        </div>
      </div>
      <div class="homeIconLine mbm50">
        <div class="imgBox mrt50">
          <img src="../../assets/images/homePic3.png" class="homePic bg-color-95CDF7"/>
          <p class="p36">AI智能大屏</p>
        </div>
        <div class="imgBox mrt50">
          <img src="../../assets/images/homePic5.png" class="homePic"/>
          <p class="p36">人脸识别消费机</p>
        </div>
      </div>
    </div>
    <div class="flexBoxCol mtp50 mbm50">
      <div class="boxLine bg-color-F2F3F2">
        <div class="fl-col-center mbm25">
          <p class="p48-700">智能闸机通行系统</p>
          <p class="p24">人脸识别、无感考勤、快速通行、消息推送、考勤自动统计</p>
        </div>
        <div class="fl-row-center flex-justify-around">
          <img src="../../assets/images/product1.png" class="imageSize"/>
          <div style="width: 60%" class="flex-wrap fl-row-center">
            <div class="fl-col flex-start wd-50 mbm25">
              <span class="p24 mbm15">智能闸机通行系统</span>
              <span class="span16">针对不同地点、不同时段、不同人群</span>
            </div>
            <div class="fl-col flex-start wd-50 mbm25">
              <span class="p24 mbm15">自动考勤</span>
              <span class="span16">刷脸后自动汇总考勤，一键导出，方便快捷</span>
            </div>
            <div class="fl-col flex-start wd-50 mbm25">
              <span class="p24 mbm15">陌生人禁止进入</span>
              <span class="span16">未录入人脸的陌生人员扫描到直接</span>
              <span class="span16">报警提示安保人员注意</span>
            </div>
            <div class="fl-col flex-start wd-50 mbm25">
              <span class="p24 mbm15">消息自动推送</span>
              <span class="span16">进出记录自动第一时间推送，家长及时了解</span>
              <span class="span16">孩子的动态，让家长安心</span>
            </div>
            <div class="fl-col flex-start wd-50">
              <span class="p24 mbm15">出入记录查询</span>
              <span class="span16">出入历史同步后台，校方管理人员随时可查</span>
            </div>
          </div>
        </div>
      </div>
      <div class="boxLine bg-color-95CDF7">
        <div class="fl-col-center mbm25">
          <p class="p48-700">智能校务管理系统</p>
        </div>
        <div class="fl-row-center flex-justify-around">
          <img src="../../assets/images/productPic2.png" class="imageSize" style="width:550px;height: 500px;"/>
          <div class="fl-row-center" style="width: 50%">
            <div class="fl-col mbm25 flex-start">
              <span class="p24-fff mbm15">通过对学生管理、教师管理、班级管理、校园宣传等功能，</span>
              <span class="p24-fff mbm25">实现了学校教务管理移动化、便捷化。</span>
              <div class="fl-row-center wd-100 mbm25">
                <div class="p36-fff mrt50 border-box-fff">学生管理</div>
                <div class="p36-fff border-box-fff" >教师管理</div>
              </div>
              <div class="fl-row-center wd-100 mbm25">
                <div class="p36-fff mrt50 border-box-fff">班级管理</div>
                <div class="p36-fff border-box-fff" >校园宣传</div>
              </div>
              <div class="fl-row-center wd-100">
                <div class="p36-fff mrt50 border-box-fff">通知管理</div>
                <div class="p36-fff border-box-fff" >作业管理</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="boxLine bg-color-ffffff">
        <div class="fl-col-center mbm25">
          <p class="p48-700">家校沟通</p>
          <span class="p24">已微信小程序为载体，为老师和家长提供了“学校通知、班级通知、双码接龙、作业助手、请假管理、考勤汇总、</span>
          <span class="p24">消息推送、智能相册”等全场景服务，极大的提高了学校和家长之间的沟通效率。</span>
        </div>
        <div class="fl-row-center flex-justify-around">
          <div class="imageSize mbm25" style="position: relative">
            <img src="../../assets/images/productPic3.png" class="imageSize"/>
            <span style="position: absolute;bottom: -55px;left: 30px" class="span24">家长端小程序</span>
            <span style="position: absolute;bottom: -55px;left: 320px" class="span24">教师端小程序</span>
          </div>
          <div class="fl-row-center" style="width: 50%">
            <div class="fl-col mbm25 flex-start">
              <span class="span24 mbm15 mbm25">作业助手</span>
              <span class="span24 mbm50 textLft">老师上传作业后，微信就会给家长推送消息，让家长督促学生又快又好得到完成作业，作业可直接拍照上传小程序，
                   老师能够在线点评，简化了流程，增加了家长的参与度。</span>
              <span class="span24 mbm25">接龙助手</span>
              <span class="span24 textLft">疫情防控下，学校需要家长上传双码时，可由老师发起，家长接龙上传。保障了学生的安全，方便了学校的管理。</span>
            </div>
          </div>
        </div>
      </div>
      <div class="boxLine bg-color-F2F3F2">
        <div class="fl-col-center mbm25">
          <p class="p48-700">校园智慧消费系统</p>
        </div>
        <div class="fl-row-center flex-justify-around">
          <div style="width: 500px">
            <p class="span24 textLft">食堂、校园超市等校内消费场所，
              支持学生刷脸支付，也支持刷卡支付，
              电子账户支持家长在线充值，无需线下排队等候
              消费详情实时推送，消费记录永久保存</p>
            <img src="../../assets/images/homePic5.png" class="imageSize"/>
          </div>
          <img src="../../assets/images/productPic4.png" class="imageSize" style="height: 500px"/>
        </div>
      </div>
      <div class="boxLine bg-color-95CDF7">
        <div class="fl-col-center mbm25">
          <p class="p48-700-fff">智能电子学生证</p>
        </div>
        <div class="fl-row-center flex-justify-around">
          <img src="../../assets/images/productPic5.png" class="imageSize" style="height: 400px"/>
          <div class="p24-fff textLft wd-50">解决了智能手机、电话手表禁止入校后，家长和孩子的通话联系人管理及使用时间管理帮助家长
            更好的督促小孩的健康成长小孩、家长、老师必要情况及时沟通</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  components: {

  },
  data () {
    return {
      casHeight: '500px',
      picList: [
        {
          src: require('../../assets/images/caseBG.png')
        }
      ],
      caseList: [
        {
          name: '大祥一中',
          pic: require('@/assets/images/daxiangCase.jpg'),
          desc1: '大祥区第一实验中学正式成立于2005年2月，是原大祥区一中（西区一中）、二纺机子弟学校与湘印机子弟学校初中' +
              '部强强联合组建而成。占地26亩，学校30个教学班的规模，在校学生2100余人。',
          desc2: '学校引进斯点智慧校园后，通过闸机通行机器和电子学生证的铺设，解决了家长们的通话需求，极大提升了校方的安全管理能力。'
        },
        {
          name: '新渡学校',
          pic: require('@/assets/images/xinduCase.png'),
          desc1: '新渡小学位于邵阳市大祥区，占地面积约20亩，学校有16个教学班级，在校师生约1000人。',
          desc2: '学校引进斯点智慧校园后，满足了家长和学生之间的电话沟通需求，促进了家长和老师之间的沟通，提升了学校的美誉度，家长放心，老师安心！'
        },
        {
          name: '三八亭小学',
          pic: require('@/assets/images/38Case.png'),
          desc1: '三八亭小学占地36亩，学校30个教学班的规模，在校学生2000余人。',
          desc2: '学校引进斯点智慧校园后，提高了学校的管理效率，增加了老师和家长之间的沟通，让学校更加智能化、现代化。'
        },
        {
          name: '祥凤实验学校',
          pic: require('@/assets/images/xiangfengCase.png'),
          desc1: '祥凤实验学校为全国青少年校园足球特色学校，学校55个教学班的规模，在校学生2600余人。',
          desc2: '学校原本无智能出入管理系统，引进智慧校园后，完善了对各大校门出入口的管理建设，实现了无感自动化考勤，进出信息自动推送，在不影响校园通行效率的情况下强化了校园安全建设。'
        },
        {
          name: '百春园小学',
          pic: require('@/assets/images/baichunyuanCase.png'),
          desc1: '百春园小学约有师生1000人。',
          desc2: '校园引进了无感面部测温仪、人脸识别系统，增强了校园安全性，赋能学校出入管理能力。'
        },
        {
          name: '城南小学',
          pic: require('@/assets/images/chengnanCase.png'),
          desc1: '三八亭小学占地36亩，学校30个教学班的规模，在校学生2000余人。',
          desc2: '学校引进斯点智慧校园后，提高了学校的管理效率，增加了老师和家长之间的沟通，让学校更加智能化、现代化。'
        }
      ]
    }
  }
}
</script>
<style scoped lang="less">
  .homeIconLine {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 65px;
  }
  .homeIconBox {
    display: flex;
    align-items: center;
    margin-right: 50px;
    width: 260px;
    justify-content: center;
    padding: 15px 20px;
    border: #BBBBBB solid 1px;
    border-radius: 10px;
    box-shadow: 5px 5px 3px rgba(0,0,0,0.3)
  }
  .imgBox {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .homePic {
    width: 400px;
    height: 255px;
    padding: 15px;
    background: #F2F3F2;
    border-radius: 10px;
  }

  .imageSize {
    width: 500px;
    height: 300px;
    object-fit: fill;
  }
  .boxLine {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #95CDF7;
    padding: 50px 0;
    width: 100%;
  }

  .border-box-fff {
    border: #ffffff solid 2px;
    padding: 15px;
    border-radius: 10px;
  }
</style>
